// At quite a few points in Fresco, there are times when a value might not need to be actually set in the CSS, because it inherits a parent or default value.

// For example, take the header search. We might want to style the button background colour with $header-search-button-background-colour. There is another variable for styling the button when it is in the navigation: $nav-search-button-background-colour; which inherits this initial value. If the values match, then there's no point us setting a more specific ruleset for the search button when in the nav. We can just cascade down from the less specific ruleset, we don't need to waste CSS here.

@mixin if-required (
  $attribute,
  $existing-value,
  $new-value
) {
  @if $new-value != $existing-value {
    #{$attribute}: $new-value;
  }
}